import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { createI18n } from "vue-i18n";
import "aos/dist/aos.css"; // Importation du fichier CSS de AOS
import AOS from "aos";
import FR from "./locale/fr.json";
import EN from "./locale/en.json";
import NL from "./locale/nl.json";

import "./app.scss";

//import "bootstrap/dist/js/bootstrap.js";

const app = createApp(App);

const i18n = createI18n({
  fallbackLocale: "FR",
  reloadOnLanguageChange: false,
  messages: {
    FR: FR,
    NL: NL,
    EN: EN,
  },
});

i18n.global.locale = "FR";

app.config.globalProperties.$aos = AOS.init({
  duration: 2000,
  once: false,
  offset: 50,
  mirror: true,
});

app.use(i18n);
app.use(store);
app.use(router);

app.mount("#app");
