import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Bike from "../views/Bike.vue";
import Paper from "../views/Paper.vue";
import Contact from "../views/Contact.vue";
import Layout from "../views/Layout.vue";

const routes = [
  {
    path: "/",
    name: "public",
    component: Layout,
    children: [
      { path: "/", name: "home", component: Home },
      { path: "/velo-publicitaire", name: "bike", component: Bike },
      { path: "/papier-ensemence", name: "paper", component: Paper },
      { path: "/contact", name: "contact", component: Contact },
      { path: "/:pathMatch(.*)*", redirect: "/" },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0, behavior: "instant" };
    }
  },
});

export default router;
