<template>
  <div id="public-layout">
    <!--Masthead-->
    <header class="masthead-bike">
      <div class="container px-4 px-lg-5 h-100">
        <div
          class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center"
        >
          <div class="col-lg-8 align-self-end">
            <h1 class="text-white font-weight-bold">{{ $t("bike.title") }}</h1>
            <hr class="divider" />
          </div>
          <div class="col-lg-8 align-self-baseline">
            <p class="text-white mb-5">
              {{ $t("bike.intro") }}
            </p>
          </div>
        </div>
      </div>
    </header>

    <!--explication-->
    <div class="bg-light pt-5 pb-5 px-4" id="Explain">
      <div class="container text-center">
        <h2 class="mt-0">{{ $t("bike.explication.title") }}</h2>
        <hr class="divider mb-5" />
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-7 center-explain mb-5">
            <p class="mb-0">
              {{ $t("bike.explication.text") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Impact -->
    <div class="bg-primary pt-5 pb-5 px-4 text-white">
      <div class="container">
        <h2 class="mt-0 mb-5 text-center">{{ $t("bike.impact.title") }}</h2>
        <div class="mb-5 div-center responsive-div">
          <!-- Card 1 -->
          <div class="card impact-card mb-4 bg-light">
            <div class="row no-gutters">
              <div class="col-12 col-md-3 d-flex justify-content-center">
                <img
                  src="@/assets/icon/visibility.svg"
                  class="impact-card-img-left margin-icon"
                  :alt="$t('bike.impact.cardimg1')"
                />
              </div>
              <div class="col-md-9">
                <div class="impact-card-body">
                  <h5 class="impact-card-title">
                    {{ $t("bike.impact.cardtitle1") }}
                  </h5>
                  <p class="impact-card-text">
                    {{ $t("bike.impact.cardtext1") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Card 2 -->
          <div class="card impact-card mb-4 bg-light">
            <div class="row no-gutters">
              <div class="col-12 col-md-3 d-flex justify-content-center">
                <img
                  src="@/assets/icon/brain.svg"
                  class="impact-card-img-left margin-icon"
                  :alt="$t('bike.impact.cardimg2')"
                />
              </div>
              <div class="col-md-9">
                <div class="impact-card-body">
                  <h5 class="impact-card-title">
                    {{ $t("bike.impact.cardtitle2") }}
                  </h5>
                  <p class="impact-card-text">
                    {{ $t("bike.impact.cardtext2") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Card 3 -->
          <div class="card impact-card mb-4 bg-light">
            <div class="row no-gutters">
              <div class="col-12 col-md-3 d-flex justify-content-center">
                <img
                  src="@/assets/icon/increase.svg"
                  class="impact-card-img-left margin-icon"
                  :alt="$t('bike.impact.cardimg3')"
                />
              </div>
              <div class="col-md-9">
                <div class="impact-card-body">
                  <h5 class="impact-card-title">
                    {{ $t("bike.impact.cardtitle3") }}
                  </h5>
                  <p class="impact-card-text">
                    {{ $t("bike.impact.cardtext3") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Advantage -->
    <div class="advantage-section">
      <div class="container">
        <h2 class="advantage-heading">{{ $t("bike.advantage.title") }}</h2>
        <p class="text-center mb-5">
          {{ $t("bike.advantage.subtitle") }}
        </p>
        <div class="row justify-content-center">
          <div
            class="col-md-6 col-lg-4 advantage-item"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              src="@/assets/icon/leaf.svg"
              :alt="$t('bike.advantage.infoimg1')"
              class="advantage-icon text-primary mb-4"
              width="48px"
            />
            <div class="advantage-title">
              {{ $t("bike.advantage.infotitle1") }}
            </div>
            <div class="advantage-description">
              {{ $t("bike.advantage.infotext1") }}
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 advantage-item"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              src="@/assets/icon/strong.svg"
              :alt="$t('bike.advantage.infoimg2')"
              class="advantage-icon text-primary mb-4"
              width="48px"
            />

            <div class="advantage-title">
              {{ $t("bike.advantage.infotitle2") }}
            </div>
            <div class="advantage-description">
              {{ $t("bike.advantage.infotext2") }}
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 advantage-item"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              src="@/assets/icon/silence.svg"
              :alt="$t('bike.advantage.infoimg3')"
              class="advantage-icon text-primary mb-4"
              width="48px"
            />
            <div class="advantage-title">
              {{ $t("bike.advantage.infotitle3") }}
            </div>
            <div class="advantage-description">
              {{ $t("bike.advantage.infotext3") }}
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 advantage-item"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              src="@/assets/icon/recycle.svg"
              :alt="$t('bike.advantage.infoimg4')"
              class="advantage-icon text-primary mb-4"
              width="48px"
            />
            <div class="advantage-title">
              {{ $t("bike.advantage.infotitle4") }}
            </div>
            <div class="advantage-description">
              {{ $t("bike.advantage.infotext4") }}
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 advantage-item"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              src="@/assets/icon/solid.svg"
              :alt="$t('bike.advantage.infoimg5')"
              class="advantage-icon text-primary mb-4"
              width="48px"
            />
            <div class="advantage-title">
              {{ $t("bike.advantage.infotitle5") }}
            </div>
            <div class="advantage-description">
              {{ $t("bike.advantage.infotext5") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Itinerary-->
    <div class="bg-primary pt-5 pb-5 px-4 text-white">
      <div class="container">
        <h2 class="mt-0 mb-5 text-center">
          {{ $t("bike.itinerary.title") }}
        </h2>
        <div class="row">
          <!-- Card 1 -->
          <div class="col-md-4 mb-4">
            <div class="card h-100">
              <img
                src="@/assets/img/axe.jpeg"
                class="card-img-top"
                :alt="$t('bike.itinerary.cardimg1')"
              />
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("bike.itinerary.cardtitle1") }}
                </h5>
                <p class="card-text">
                  {{ $t("bike.itinerary.cardtext1") }}
                </p>
              </div>
            </div>
          </div>
          <!-- Card 2 -->
          <div class="col-md-4 mb-4">
            <div class="card h-100">
              <img
                src="@/assets/img/place-bondee.jpeg"
                class="card-img-top"
                :alt="$t('bike.itinerary.cardimg2')"
              />
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("bike.itinerary.cardtitle2") }}
                </h5>
                <p class="card-text">
                  {{ $t("bike.itinerary.cardtext2") }}
                </p>
              </div>
            </div>
          </div>
          <!-- Card 3 -->
          <div class="col-md-4 mb-4">
            <div class="card h-100">
              <img
                src="@/assets/img/marche2.jpeg"
                class="card-img-top"
                :alt="$t('bike.itinerary.cardimg3')"
              />
              <div class="card-body">
                <h5 class="card-title">
                  {{ $t("bike.itinerary.cardtitle3") }}
                </h5>
                <p class="card-text">
                  {{ $t("bike.itinerary.cardtext3") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Why US-->
    <div class="pt-5 px-4">
      <div class="container">
        <h2 class="mt-0 mb-5 text-center">{{ $t("bike.why-us.title") }}</h2>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-7 mb-5 text-center">
            <p>
              {{ $t("bike.why-us.text1") }}
            </p>
            <p>
              {{ $t("bike.why-us.text2") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- How it works -->
    <div class="bg-primary py-5">
      <div class="container mt-5 text-center">
        <h2 class="mt-0 mb-5 text-white">
          {{ $t("bike.how-works.title") }}
        </h2>
        <div class="row d-flex justify-content-around">
          <div class="col-lg-3 mb-5" data-aos="fade-up">
            <div class="bg-light card py-4 px-1">
              <div class="mb-3">
                <img
                  src="@/assets/icon/one.svg"
                  :alt="$t('bike.how-works.cardimg1')"
                  width="48px"
                />
              </div>
              <div class="step-content">
                <h4>{{ $t("bike.how-works.cardtitle1") }}</h4>
                <p class="mb-0">
                  {{ $t("bike.how-works.cardtext1") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-5" data-aos="fade-up">
            <div class="bg-light card py-4 px-1">
              <div class="mb-3">
                <img
                  src="@/assets/icon/two.svg"
                  :alt="$t('bike.how-works.cardimg2')"
                  width="48px"
                />
              </div>
              <div class="step-content">
                <h4>{{ $t("bike.how-works.cardtitle2") }}</h4>
                <p class="mb-0">
                  {{ $t("bike.how-works.cardtext2") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-5" data-aos="fade-up">
            <div class="bg-light card py-4 px-1">
              <div class="mb-3">
                <img
                  src="@/assets/icon/three.svg"
                  :alt="$t('bike.how-works.cardimg3')"
                  width="48px"
                />
              </div>
              <div class="step-content">
                <h4>{{ $t("bike.how-works.cardtitle3") }}</h4>
                <p class="mb-0">
                  {{ $t("bike.how-works.cardtext3") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-5" data-aos="fade-up">
            <div class="bg-light card py-4 px-1">
              <div class="mb-3">
                <img
                  src="@/assets/icon/four.svg"
                  :alt="$t('bike.how-works.cardimg4')"
                  width="48px"
                />
              </div>
              <div class="step-content">
                <h4>{{ $t("bike.how-works.cardtitle4") }}</h4>
                <p class="mb-0">
                  {{ $t("bike.how-works.cardtext4") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FAQ -->
    <div class="py-5 mb-5 px-4">
      <div class="container">
        <h2 class="mt-0 mb-5 text-center">{{ $t("bike.faq.title") }}</h2>
        <hr class="divider" />
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  {{ $t("bike.faq.question1") }}
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {{ $t("bike.faq.answer1") }}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  {{ $t("bike.faq.question2") }}
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {{ $t("bike.faq.answer2") }}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{ $t("bike.faq.question3") }}
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {{ $t("bike.faq.answer3") }}
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  aria-expanded="false"
                  aria-controls="collapse4"
                >
                  {{ $t("bike.faq.question4") }}
                </button>
              </h2>
              <div
                id="collapse4"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {{ $t("bike.faq.answer4") }}
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse5"
                  aria-expanded="false"
                  aria-controls="collapse5"
                >
                  {{ $t("bike.faq.question5") }}
                </button>
              </h2>
              <div
                id="collapse5"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {{ $t("bike.faq.answer5") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Call to action-->
    <section class="page-section bg-dark text-white">
      <div class="container px-4 px-lg-5 text-center">
        <a class="btn btn-light btn-xl" href="/contact">{{
          $t("btn-contact")
        }}</a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Bike",
};
</script>

<style scoped>
.div-center {
  margin-left: auto;
  margin-right: auto;
}
.back-nav {
  height: 72px;
}

.advantage-section {
  background-color: #f9f9f9;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.advantage-heading {
  margin-top: 0;
  margin-bottom: 3rem;
  text-align: center;
  font-weight: bold;
}
.advantage-item {
  text-align: center;
  margin-bottom: 2rem;
}
.advantage-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.advantage-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.advantage-description {
  font-size: 1rem;
  line-height: 1.6;
}

.card {
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card-img-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  max-height: 320px;
  object-fit: cover;
}

.card-body {
  padding: 1.5rem;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.card-text {
  font-size: 1rem;
}

.impact-card {
  background-color: transparent;
  border: none;
}

.impact-card-img-left {
  width: 100px;
  height: auto;
  border-radius: 10px;
}

.impact-card-body {
  padding: 1rem;
}

.impact-card-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.impact-card-text {
  font-size: 1rem;
}

.responsive-div {
  width: 100%; /* Default width for small screens */
  text-align: center;
}

.margin-icon {
  margin-top: 24px;
}

@media (min-width: 992px) {
  .responsive-div {
    width: 75%; /* Width for screens larger than 992px */
  }
}

@media (min-width: 768px) {
  .responsive-div {
    text-align: left;
  }
  .margin-icon {
    margin-top: 0px;
  }
}
</style>
