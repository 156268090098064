<template>
  <div id="contact-page">
    <div class="nav-space"></div>
    <div id="head-contact" class="bg-primary-gradient">
      <h1>{{ $t("contact.title") }}</h1>
    </div>
    <!-- Contact-->
    <section class="page-section pt-3" id="contact">
      <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5 justify-content-center">
          <div class="col-lg-8 col-xl-6 text-center">
            <hr class="divider" />
            <p class="text-muted mb-5">
              {{ $t("contact.text") }}
            </p>
          </div>
        </div>
        <div class="row gx-4 gx-lg-5 justify-content-center mb-5">
          <div class="col-lg-6">
            <form
              id="contactForm"
              v-if="!submitted"
              @submit.prevent="submitForm"
            >
              <!-- Lastname input-->
              <div class="form-floating mb-3">
                <input
                  class="form-control"
                  id="lastname"
                  type="text"
                  placeholder=""
                  v-model="data.lastname"
                  @blur="validateField('lastname')"
                  :class="{ 'is-invalid': errors.lastname }"
                />
                <label for="lastname">{{ $t("contact.input1") }}</label>
                <div class="invalid-feedback">
                  {{ errors.lastname }}
                </div>
              </div>
              <!-- Name input-->
              <div class="form-floating mb-3">
                <input
                  class="form-control"
                  id="name"
                  type="text"
                  placeholder=""
                  v-model="data.firstname"
                  @blur="validateField('firstname')"
                  :class="{ 'is-invalid': errors.firstname }"
                />
                <label for="name">{{ $t("contact.input2") }}</label>
                <div class="invalid-feedback">
                  {{ errors.firstname }}
                </div>
              </div>
              <!-- Company input-->
              <div class="form-floating mb-3">
                <input
                  class="form-control"
                  id="company"
                  type="text"
                  placeholder=""
                  v-model="data.company"
                  @blur="validateField('company')"
                  :class="{ 'is-invalid': errors.company }"
                />
                <label for="company">{{ $t("contact.input3") }}</label>
                <div class="invalid-feedback">
                  {{ errors.company }}
                </div>
              </div>
              <!-- Email address input-->
              <div class="form-floating mb-3">
                <input
                  class="form-control"
                  id="email"
                  type="email"
                  placeholder=""
                  v-model="data.email"
                  @blur="validateField('email')"
                  :class="{ 'is-invalid': errors.email }"
                />
                <label for="email">{{ $t("contact.input4") }}</label>
                <div class="invalid-feedback">
                  {{ errors.email }}
                </div>
              </div>
              <!-- Phone number input-->
              <div class="form-floating mb-3">
                <input
                  class="form-control"
                  id="phone"
                  type="tel"
                  placeholder=""
                  v-model="data.phone"
                  @blur="validateField('phone')"
                  :class="{ 'is-invalid': errors.phone }"
                />
                <label for="phone">{{ $t("contact.input5") }}</label>
                <div class="invalid-feedback">
                  {{ errors.phone }}
                </div>
              </div>
              <!-- Message input-->
              <div class="form-floating mb-3">
                <textarea
                  class="form-control"
                  id="message"
                  placeholder=""
                  style="height: 10rem"
                  v-model="data.message"
                  @blur="validateField('message')"
                  :class="{ 'is-invalid': errors.message }"
                ></textarea>
                <label for="message">{{ $t("contact.input6") }}</label>
                <div class="invalid-feedback">
                  {{ errors.message }}
                </div>
              </div>
              <!-- Submit Button-->
              <div class="d-grid">
                <button
                  class="btn btn-primary btn-xl"
                  id="submitButton"
                  type="submit"
                >
                  {{ $t("contact.button") }}
                </button>
              </div>
            </form>
            <!-- Success Message -->
            <div v-if="submitted" class="alert alert-success mt-4">
              {{ $t("contact.success-message") }} <br />
              <a class="btn btn-primary rounded-pill mt-4" href="/">
                {{ $t("contact.success-btn") }}</a
              >
            </div>

            <!-- Error Message -->
            <div v-if="error" class="alert alert-danger mt-4">
              {{ $t("contact.error-message") }}
            </div>
          </div>
        </div>

        <!-- Info contact -->

        <div class="row gx-4 gx-lg-5 justify-content-center">
          <div class="col-lg-4 text-center mb-5 mb-lg-0">
            <i class="bi-phone fs-2 mb-3 text-muted"></i>
            <div>
              <a href="tel:+32486955898">+32 488 10 95 30</a>
            </div>
          </div>
          <div class="col-lg-4 text-center mb-5 mb-lg-0">
            <i class="bi-envelope fs-2 mb-3 text-muted"></i>
            <div>contact@ecopub.be</div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      data: {
        firstname: "",
        lastname: "",
        company: "",
        email: "",
        phone: "",
        message: "",
      },
      errors: {},
      submitted: false,
      error: false,
    };
  },
  methods: {
    validateField(field) {
      if (!this.data[field]) {
        this.errors[field] = this.$t("contact.required");
      } else if (field === "email" && !this.validEmail(this.data.email)) {
        this.errors[field] = this.$t("contact.invalid-email");
      } else {
        delete this.errors[field];
      }
    },
    validEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    },
    async submitForm() {
      Object.keys(this.data).forEach((field) => this.validateField(field));
      if (Object.values(this.errors).every((error) => !error)) {
        try {
          const Axios = axios.create({
            baseURL: "https://ecopub.be",
          });
          await Axios.post("/sendmail.php", this.data);
          this.submitted = true;
          this.error = false;
        } catch (err) {
          this.error = true;
        }
      }
    },
    /* async submitForm() {
      Object.keys(this.data).forEach((field) => this.validateField(field));
      if (Object.values(this.errors).every((error) => !error)) {
        try {
          const Axios = axios.create({
            baseURL: "http://192.168.129.78:8989",
          });
          await Axios.post("/form-contact", this.data);
          this.submitted = true;
          this.error = false;
        } catch (err) {
          this.error = true;
        }
      }
    }, */
  },
};
</script>

<style scoped>
.nav-space {
  height: 72px;
}
#head-contact {
  color: white;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
