<template>
  <div>
    <!-- Navigation-->
    <nav
      class="navbar navbar-expand-lg navbar-light fixed-top py-3"
      id="mainNav"
    >
      <div class="container px-4 px-lg-5">
        <router-link class="navbar-brand" to="/"
          ><img src="@/assets/logo.png" alt="ecopub" height="48px"
        /></router-link>
        <button
          @click="btnClick"
          class="navbar-toggler navbar-toggler-right"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ms-auto my-2 my-lg-0">
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="{
                  'nav-item': true,
                  active: $route.path === '/',
                }"
                to="/"
                >{{ $t("nav.link1") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="{
                  'nav-item': true,
                  active: $route.path === '/velo-publicitaire',
                }"
                to="/velo-publicitaire"
                >{{ $t("nav.link2") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="{
                  'nav-item': true,
                  active: $route.path === '/papier-ensemence',
                }"
                to="/papier-ensemence"
                >{{ $t("nav.link3") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                :class="{
                  'nav-item': true,
                  active: $route.path === '/contact',
                }"
                class="nav-link"
                to="/contact"
                >{{ $t("nav.link4") }}</router-link
              >
            </li>
          </ul>
          <div class="language-selector">
            <div class="selected-language" @click="toggleDropdown">
              <img
                :src="selectedLanguageIcon"
                alt="Icône de la langue sélectionnée"
                class="language-icon"
              />
            </div>
            <transition name="fade">
              <div v-if="showDropdown" class="language-options">
                <div
                  v-for="lang in supportedLanguages"
                  :key="lang.code"
                  @click="
                    changeLanguage(lang.code);
                    closeMenu();
                  "
                >
                  <img
                    :src="lang.icon"
                    alt="Icône de la langue"
                    class="language-icon"
                  />

                  <span class="selected-language font mx-2">
                    {{ lang.name }}</span
                  >
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      supportedLanguages: [
        { code: "FR", name: "Fr", icon: require("@/assets/img/BE.png") },
        {
          code: "NL",
          name: "Nl",
          icon: require("@/assets/img/BE.png"),
        },
        { code: "EN", name: "En", icon: require("@/assets/img/EN.png") },
      ],
      selectedLanguage: this.$i18n.locale,
      showDropdown: false,
    };
  },
  watch: {
    $route() {
      // Navbar shrink function
      var navbarShrink = function () {
        const navbarCollapsible = document.body.querySelector("#mainNav");
        if (!navbarCollapsible) {
          return;
        }
        if (window.location.pathname === "/") {
          if (window.scrollY === 0) {
            navbarCollapsible.classList.remove("navbar-shrink");
          } else {
            navbarCollapsible.classList.add("navbar-shrink");
          }
        } else {
          navbarCollapsible.classList.add("navbar-shrink");
        }
      };

      // Shrink the navbar
      navbarShrink();
    },
  },
  mounted() {
    // Navbar shrink function
    var navbarShrink = function () {
      const navbarCollapsible = document.body.querySelector("#mainNav");
      if (!navbarCollapsible) {
        return;
      }
      if (window.location.pathname === "/") {
        if (window.scrollY === 0) {
          navbarCollapsible.classList.remove("navbar-shrink");
        } else {
          navbarCollapsible.classList.add("navbar-shrink");
        }
      } else {
        navbarCollapsible.classList.add("navbar-shrink");
      }
    };

    // Shrink the navbar
    navbarShrink();

    // Shrink the navbar when page is scrolled
    document.addEventListener("scroll", navbarShrink);
    // Activate Bootstrap scrollspy on the main nav element
    const mainNav = document.body.querySelector("#mainNav");
    if (mainNav) {
      new bootstrap.ScrollSpy(document.body, {
        target: "#mainNav",
        rootMargin: "0px 0px -40%",
      });
    }

    // Collapse responsive navbar when toggler is visible
    const navbarToggler = document.body.querySelector(".navbar-toggler");
    const responsiveNavItems = [].slice.call(
      document.querySelectorAll("#navbarResponsive .nav-link")
    );
    responsiveNavItems.map(function (responsiveNavItem) {
      responsiveNavItem.addEventListener("click", () => {
        if (window.getComputedStyle(navbarToggler).display !== "none") {
          navbarToggler.click();
        }
      });
    });
  },

  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    changeLanguage(langCode) {
      this.$i18n.locale = langCode;
      this.selectedLanguage = langCode;
      this.toggleDropdown(); // Fermer la liste déroulante après avoir sélectionné une langue
    },
    closeMenu() {
      // Fermer le menu lorsque la langue est changée
      document.getElementById("navbarResponsive").classList.remove("show");
    },
  },
  computed: {
    selectedLanguageIcon() {
      return this.supportedLanguages.find(
        (lang) => lang.code === this.selectedLanguage
      ).icon;
    },
  },
};
</script>

<style scoped>
.center-explain {
  display: flex;
  align-items: center;
}

.language-selector {
  position: relative;
}

.selected-language {
  cursor: pointer;
}

.language-options {
  position: absolute;
  top: 30px; /* Ajuster la position verticale selon vos besoins */
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  padding-left: 8px;
  width: 75px;
}

.language-icon {
  width: 24px; /* Ajustez la taille de l'icône selon vos besoins */
  height: 24px;
}

.font {
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media (min-width: 992px) {
  .language-selector {
    margin-left: 40px;
  }
}

@media (max-width: 991px) {
  .language-selector {
    margin-top: 40px;
  }
}
</style>
