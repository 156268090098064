<template>
  <div id="public-layout">
    <div id="content-wrap">
      <Nav />
      <router-view />
    </div>
    <footer id="footer">
      <Footer />
    </footer>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Layout",
  components: {
    Nav,
    Footer,
  },
};
</script>

<style>
#public-layout {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 117px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 117px;
}
</style>
