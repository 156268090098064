<template>
  <div id="page-top">
    <div class="block-down"></div>
    <!-- Masthead-->
    <header class="masthead">
      <div class="container px-4 px-lg-5 h-100">
        <div
          class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center"
        >
          <div class="col-lg-8 align-self-end">
            <h2 class="text-white font-weight-bold">
              {{ $t("home.title") }}
            </h2>
            <hr class="divider" />
          </div>
          <div class="col-lg-8 align-self-baseline">
            <p class="text-white-75 mb-5">
              {{ $t("home.intro") }}
            </p>
          </div>
        </div>
      </div>
    </header>

    <!-- bike and paper-->
    <div class="container my-5 py-4">
      <div class="row d-flex justify-content-center">
        <div class="col-md-5 col-sm-12 mb-4 mx-3">
          <div class="card position-relative">
            <img
              src="@/assets/img/header.png"
              :alt="$t('home.bike-paper.bike.img')"
              class="img-cover"
              height="300px"
            />
            <div class="card-body text-center">
              <h3 class="card-title mb-5">
                {{ $t("home.bike-paper.bike.main-title") }}
              </h3>
              <div>
                <div class="mb-5 pb-3">
                  <h5 class="text-primary">
                    {{ $t("home.bike-paper.bike.title1") }}
                  </h5>
                  {{ $t("home.bike-paper.bike.paragraph1") }}
                </div>
                <div class="mb-5 pb-3">
                  <h5 class="text-primary">
                    {{ $t("home.bike-paper.bike.title2") }}
                  </h5>
                  {{ $t("home.bike-paper.bike.paragraph2") }}
                </div>
                <div class="mb-5 pb-3">
                  <h5 class="text-primary">
                    {{ $t("home.bike-paper.bike.title3") }}
                  </h5>
                  {{ $t("home.bike-paper.bike.paragraph3") }}
                </div>
              </div>
              <a
                class="btn btn-primary position-absolute bottom-button"
                href="/velo-publicitaire"
              >
                {{ $t("home.bike-paper.bike.btn") }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-12 mb-4 mx-3">
          <div class="card position-relative">
            <img
              src="@/assets/img/papierplante.jpg"
              :alt="$t('home.bike-paper.paper.img')"
              class="img-cover"
              height="300px"
            />
            <div class="card-body text-center">
              <h3 class="card-title mb-5">
                {{ $t("home.bike-paper.paper.main-title") }}
              </h3>
              <div>
                <div class="mb-5 pb-3">
                  <h5 class="text-primary">
                    {{ $t("home.bike-paper.paper.title1") }}
                  </h5>
                  {{ $t("home.bike-paper.paper.paragraph1") }}
                </div>
                <div class="mb-5 pb-3">
                  <h5 class="text-primary">
                    {{ $t("home.bike-paper.paper.title2") }}
                  </h5>
                  {{ $t("home.bike-paper.paper.paragraph2") }}
                </div>
                <div class="mb-5 pb-3">
                  <h5 class="text-primary">
                    {{ $t("home.bike-paper.paper.title3") }}
                  </h5>
                  {{ $t("home.bike-paper.paper.paragraph3") }}
                </div>
              </div>
              <a
                class="btn btn-primary position-absolute bottom-button"
                href="/papier-ensemence"
              >
                {{ $t("home.bike-paper.paper.btn") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- promise-->
    <section class="bg-primary pt-5 pb-5 px-4">
      <div class="container">
        <div class="text-center">
          <h3 class="text-white mt-0">{{ $t("home.promise.title") }}</h3>
          <hr class="divider divider-light mb-5" />
          <div class="row justify-content-center">
            <div class="col-md-6 mb-4" data-aos="fade-up" data-aos-delay="300">
              <div class="card p-4">
                <h3 class="step-title text-primary">
                  {{ $t("home.promise.title1") }}
                </h3>
                <p class="step-text">
                  {{ $t("home.promise.paragraph1") }}
                </p>
              </div>
            </div>
            <div class="col-md-6 mb-4" data-aos="fade-up" data-aos-delay="100">
              <div class="card p-4">
                <h3 class="step-title text-primary">
                  {{ $t("home.promise.title2") }}
                </h3>
                <p class="step-text">
                  {{ $t("home.promise.paragraph2") }}
                </p>
              </div>
            </div>
            <div class="col-md-6 mb-4" data-aos="fade-up" data-aos-delay="200">
              <div class="card p-4">
                <h3 class="step-title text-primary">
                  {{ $t("home.promise.title3") }}
                </h3>
                <p class="step-text">
                  {{ $t("home.promise.paragraph3") }}
                </p>
              </div>
            </div>

            <div class="col-md-6 mb-4" data-aos="fade-up">
              <div class="card p-4">
                <h3 class="step-title text-primary">
                  {{ $t("home.promise.title4") }}
                </h3>
                <p class="step-text">
                  {{ $t("home.promise.paragraph4") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Call to action-->
    <section class="page-section bg-dark text-white">
      <div class="container px-4 px-lg-5 text-center">
        <div class="mb-5 pb-4">
          <P>{{ $t("home.contact-text") }}</P>
        </div>
        <a class="btn btn-light btn-xl" href="/contact">{{
          $t("btn-contact")
        }}</a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    new SimpleLightbox({
      elements: "#portfolio a.portfolio-box",
    });
  },
};
</script>

<style scoped>
.img-cover {
  object-fit: cover;
}
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.position-relative {
  position: relative;
}

.bottom-button {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.block-down {
  height: 90px;
}

@media (min-width: 992px) {
  .block-down {
    height: 0px;
  }
}
</style>
