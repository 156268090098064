<template>
  <div id="paper-section">
    <!--Masthead-->
    <header class="masthead-paper">
      <div class="container px-4 px-lg-5 h-100">
        <div
          class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center"
        >
          <div class="col-lg-8 align-self-end">
            <h1 class="text-white font-weight-bold">
              {{ $t("paper.title") }}
            </h1>
            <h3 class="text-white font-weight-bold">
              {{ $t("paper.subtitle") }}
            </h3>
            <hr class="divider" />
          </div>
          <div class="col-lg-8 align-self-baseline">
            <p class="text-white-75 mb-5">
              {{ $t("paper.textintro") }}
            </p>
          </div>
        </div>
      </div>
    </header>

    <!--Explain-->
    <section class="bg-primary pt-5 pb-5 px-4" id="Explain">
      <div class="container">
        <div class="justify-content-center">
          <div class="text-center">
            <h2 class="text-white mt-0">{{ $t("paper.explain.title") }}</h2>
            <hr class="divider divider-light mb-5" />
            <div class="row justify-content-center">
              <div class="col-lg-4 center-explain mb-5 mx-2">
                <p class="text-white-75 mb-0">
                  {{ $t("paper.explain.text") }}
                </p>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6 center-explain mb-5 mx-2">
                <img
                  src="@/assets/img/papier-ensemence2.jpg"
                  :alt="$t('paper.explain.img')"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Avantages-->
    <section class="py-5" id="services">
      <div class="container px-4 px-lg-5">
        <h2 class="text-center mt-0">{{ $t("paper.avantages.title") }}</h2>
        <hr class="divider" />
        <div class="row gx-4 gx-lg-5">
          <div class="col-lg-3 col-md-6 text-center">
            <div class="mt-5">
              <div class="mb-2">
                <i class="bi-recycle fs-1 text-primary"></i>
              </div>
              <h3 class="h4 mb-2">{{ $t("paper.avantages.title1") }}</h3>
              <p class="text-muted mb-0">
                {{ $t("paper.avantages.text1") }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 text-center">
            <div class="mt-5">
              <div class="mb-2">
                <i class="bi-flower3 fs-1 text-primary"></i>
              </div>
              <h3 class="h4 mb-2">{{ $t("paper.avantages.title2") }}</h3>
              <p class="text-muted mb-0">
                {{ $t("paper.avantages.text2") }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 text-center">
            <div class="mt-5">
              <div class="mb-2">
                <i class="bi bi-flag fs-1 text-primary"></i>
              </div>
              <h3 class="h4 mb-2">{{ $t("paper.avantages.title3") }}</h3>
              <p class="text-muted mb-0">
                {{ $t("paper.avantages.text3") }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 text-center">
            <div class="mt-5">
              <div class="mb-2">
                <i class="bi-lightbulb fs-1 text-primary"></i>
              </div>
              <h3 class="h4 mb-2">{{ $t("paper.avantages.title4") }}</h3>
              <p class="text-muted mb-0">
                {{ $t("paper.avantages.text4") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Portfolio-->
    <div id="portfolio">
      <div class="container-fluid p-0">
        <div class="row g-0">
          <div class="col-lg-4 col-sm-6">
            <a
              class="portfolio-box"
              href="assets/img/portfolio/fullsize/1.jpg"
              :title="$t('paper.portfolio.img1')"
            >
              <img
                class="img-fluid"
                src="assets/img/portfolio/thumbnails/1.png"
                :alt="$t('paper.portfolio.img1')"
              />
              <div class="portfolio-box-caption">
                <div class="project-name">{{ $t("paper.portfolio.img1") }}</div>
              </div>
            </a>
          </div>
          <div class="col-lg-4 col-sm-6">
            <a
              class="portfolio-box"
              href="assets/img/portfolio/fullsize/2.jpg"
              :title="$t('paper.portfolio.img2')"
            >
              <img
                class="img-fluid"
                src="assets/img/portfolio/thumbnails/2.jpg"
                :alt="$t('paper.portfolio.img2')"
              />
              <div class="portfolio-box-caption">
                <div class="project-name">{{ $t("paper.portfolio.img2") }}</div>
              </div>
            </a>
          </div>
          <div class="col-lg-4 col-sm-6">
            <a
              class="portfolio-box"
              href="assets/img/portfolio/fullsize/3.jpg"
              :title="$t('paper.portfolio.img3')"
            >
              <img
                class="img-fluid"
                src="assets/img/portfolio/thumbnails/3.png"
                :alt="$t('paper.portfolio.img3')"
              />
              <div class="portfolio-box-caption">
                <div class="project-name">{{ $t("paper.portfolio.img3") }}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Process growing-->
    <div class="container my-5 py-5 text-center">
      <h2 class="mt-0 mb-5">{{ $t("paper.process.title") }}</h2>
      <div class="row">
        <div class="col-lg-3 col-6" data-aos="fade-up">
          <div class="mb-3">
            <img
              src="@/assets/img/process1.png"
              :alt="$t('paper.process.img1')"
              width="100%"
            />
          </div>
          <div class="mb-3">
            <img src="@/assets/icon/one.svg" alt="étape un" width="48px" />
          </div>
          <div class="step-content">
            <p>{{ $t("paper.process.text1") }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-6" data-aos="fade-up">
          <div class="mb-3">
            <img
              src="@/assets/img/process2.png"
              :alt="$t('paper.process.img2')"
              width="100%"
            />
          </div>
          <div class="mb-3">
            <img src="@/assets/icon/two.svg" alt="étape deux" width="48px" />
          </div>
          <div class="step-content">
            <p>{{ $t("paper.process.text2") }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-6" data-aos="fade-up">
          <div class="mb-3">
            <img
              src="@/assets/img/process3.png"
              :alt="$t('paper.process.img3')"
              width="100%"
            />
          </div>
          <div class="mb-3">
            <img src="@/assets/icon/three.svg" alt="étape trois" width="48px" />
          </div>
          <div class="step-content">
            <p>{{ $t("paper.process.text3") }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-6" data-aos="fade-up">
          <div class="mb-3">
            <img
              src="@/assets/img/process4.png"
              :alt="$t('paper.process.img4')"
              width="100%"
            />
          </div>
          <div class="mb-3">
            <img src="@/assets/icon/four.svg" alt="étape quatre" width="48px" />
          </div>
          <div class="step-content">
            <p>{{ $t("paper.process.text4") }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- FAQ-->
    <div class="py-5 mb-5 px-4">
      <div class="container">
        <h2 class="mt-0 mb-5 text-center">{{ $t("paper.faq.title") }}</h2>
        <hr class="divider" />
        <div class="">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  {{ $t("paper.faq.question1") }}
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div
                  class="accordion-body"
                  v-html="$t('paper.faq.answer1')"
                ></div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  {{ $t("paper.faq.question2") }}
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div
                  class="accordion-body"
                  v-html="$t('paper.faq.answer2')"
                ></div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  {{ $t("paper.faq.question3") }}
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div
                  class="accordion-body"
                  v-html="$t('paper.faq.answer3')"
                ></div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  aria-expanded="false"
                  aria-controls="collapse4"
                >
                  {{ $t("paper.faq.question4") }}
                </button>
              </h2>
              <div
                id="collapse4"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div
                  class="accordion-body"
                  v-html="$t('paper.faq.answer4')"
                ></div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse5"
                  aria-expanded="false"
                  aria-controls="collapse5"
                >
                  {{ $t("paper.faq.question5") }}
                </button>
              </h2>
              <div
                id="collapse5"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div
                  class="accordion-body"
                  v-html="$t('paper.faq.answer5')"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Call to action-->
    <section class="page-section bg-dark text-white">
      <div class="container px-4 px-lg-5 text-center">
        <a class="btn btn-light btn-xl" href="/contact">Nous Contacter</a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Paper",
  mounted() {
    new SimpleLightbox({
      elements: "#portfolio a.portfolio-box",
    });
  },
};
</script>

<style>
.back-nav {
  height: 72px;
}

.center-explain {
  display: flex;
  align-items: center;
}
</style>
